@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap');

body {
  font-family: 'Work Sans', sans-serif;
  min-width: 360px;
}

/* Header style */
.maxHeightLogo {
  width: 25%;
}

nav.shadow.navbar.navbar-expand-lg.navbar-light.bg-white {
  padding: 0 !important;
}

/* Home page style */
.hero-section {
  background: rgb(254, 230, 209);
  background: linear-gradient(250deg, rgba(254, 230, 209, 1) 0%, rgb(238, 238, 238) 54%, rgba(255, 255, 255, 1) 100%);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.hero-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #9F9F9F;
}

.text-content {
  width: 50%;
  margin-left: 20px;
}

.text-content h1 {
  color: #FB8824;
  font-size: 33px;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.image-content {
  width: 40%;
}

.hero-image {
  max-width: 60%;
  height: auto;
}

.input-content {
  border: 3px solid #FB8824;
  border-radius: 15px;
  width: 50%;
  margin: 0 auto;
  padding: 10px;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.error-message {
  width: 50%;
  margin: 0 auto;
  margin-top: -35px;
  padding: 15px 10px;
  display: block;
  align-items: center;
  color: #e61616;
}

input {
  color: #828282;
  font-size: 22px;
  padding: 10px;
  border: none !important;
  outline: none;
  flex: 1;
  max-width: calc(100% - 10px);
}

input::placeholder {
  color: #d9d9d9;
  font-size: 22px;
}

.submit-btn {
  padding: 10px 25px;
  background-color: #FB8824;
  color: white;
  font-weight: 500;
  border: none;
  cursor: pointer;
  border-radius: 50px !important;
  padding: 10px 30px !important;
  transition: background 0.3s ease;
}

.submit-btn:hover {
  background: linear-gradient(to right, #FB8824 50%, #ffe6c8 50%);
  background-size: 200% 100%;
  background-position: -100% 0;
  color: #FB8824;
}

.gradient-section {
  /* background: rgb(254, 230, 209);
    background: linear-gradient(36deg, rgba(254, 230, 209, 1) 0%, rgb(238, 238, 238) 54%, rgba(255, 255, 255, 1) 100%); */
    background: rgb(254, 230, 209);
      background: linear-gradient(250deg, rgba(254, 230, 209, 1) 0%, rgb(238, 238, 238) 54%, rgba(255, 255, 255, 1) 100%);
}

.container-section2 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 40px;
}

.gradient-content {
  padding: 20px 0;
}

.inner-container {
  background-color: #FFFFFF;
  border: 1px solid #FB8824;
  border-radius: 20px;
  display: flex;
  color: #9F9F9F;
  font-size: 17px;
}

.column-container {
  flex: 1;
  text-align: center;
  padding: 10px 20px;
}

.text-process {
  margin-top: 10px;
}

.divider {
  position: relative;
  width: 1px;
  background-color: #FB8824;
  margin-top: 10px;
  margin-bottom: 10px;
}

.divider::before {
  content: '';
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #FB8824;
}

.additional-text {
  color: #828282;
  font-weight: 600;
  font-size: 17px;
}

/* Thank you style */
.thank-you-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
}

.thank-you-box {
  background-color: #ffffff;
  border: 2px solid #FB8824;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
}

.thank-you-box h1 {
  font-size: 35px;
  font-weight: 700;
}

/* Footer style */
.footer-section {
  background-color: #eeeeee;
  color: #828282;
  padding-top: 20px;
  padding-bottom: 20px;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
}

.logo-footer img {
  max-width: 150px;
  height: auto;
}

.disclosure-message {
  margin: 10px 20px 10px 20px;
  font-size: 17px;
}

.footer-text {
  margin-top: 20px;
  color: #FB8824;
  font-weight: 500;
}


/* =========== Media Queries =========== */
@media only screen and (max-width: 768px) {
  .hero-section {
      height: unset;
  }

  .hero-content {
    flex-direction: column;
    align-items: center;
  }

  .text-content {
    margin-left: unset;
    width: 100%;
    text-align: center;
  }

  .image-content {
    width: 100%;
    text-align: center;
    order: -1;
  }

 .hero-image {
    margin-top: 20px;
  }

  .text-content h1 {
      font-size: 29px;
  }

  .input-content {
    width: 100%;
  }

  .error-message {
    width: 100%;
    margin-top: 10px;
  }

  .inner-container {
    flex-direction: column;
  }

  .divider {
    display: none;
  }

  .column-container {
    margin-bottom: 10px;
  }

  .disclosure-message {
    margin: 30px 20px;
  }
}

@media only screen and (max-width: 400px) {
  .hero-section {
    height: unset;
  }

 .text-content {
   margin-left: unset;
 }

  .text-content h1 {
    font-size: 19px;
    margin: 10px;
    color: #FB8824;
  }

}

